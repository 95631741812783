import { Pagination as MaterialPagination, PaginationItem } from '@mui/material'
import { AxiosPromise } from 'axios'
import classNames from 'classnames'
import _ from 'lodash'
import qs from 'qs'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import ChannelContext from '../../context/ChannelContext'
import HistoryContext from '../../context/HistoryContext'
import ChildrenInterface from '../../interfaces/ChildrenInterface'
import { handleErrorResponse } from '../../utils/formErrorHelper'
import LoadingSpinner from '../LoadingSpinner'
import styles from './Pagination.module.scss'

export enum PaginationModeEnum {
  INFINITE = 'infinite',
  CLASSIC = 'classic',
}

export type ResultsComponentProps<T> = {
  items: T[] | false
  itemsCount: number
  page: number
  children?: ChildrenInterface
  pages: number
}

const DEFAULT_RESULTS_PER_PAGE = 10
const DEFAULT_INFINITE_THRESHOLD = 0.9

type Props<ResultsPropsT, ResultT> = {
  mode: PaginationModeEnum
  onPageChange?: (page: number) => void
  fetchData: (page: number, perPage: number, fetchAllPages?: boolean, rest?: object) => AxiosPromise<any>
  fetchCallback?: (items: any) => void
  resultsPerPage?: number
  ResultsComponent: React.FunctionComponent<any> | React.ComponentClass<any, any>
  resultsProps?: ResultsPropsT
  showLoader?: boolean
  showFirstButton?: boolean
  showLastButton?: boolean
  pagerPosition?: 'left' | 'center' | 'right'
  threshold?: number
  dataTransformer?: (input: any) => any
  children?: ChildrenInterface
  onLoading?: (loading: boolean) => void
  fetchProps?: object
  initItems?: ResultT[] | undefined[] | false
  skipDefaultError?: boolean
}

type SearchQueryType = {
  p: string
}

const Pagination = <ResultT extends object, ResultsPropsT extends object>(props: Props<ResultsPropsT, ResultT>) => {
  const {
    ResultsComponent,
    fetchData,
    resultsProps,
    showLoader,
    showFirstButton,
    showLastButton,
    pagerPosition,
    mode,
    dataTransformer,
    fetchCallback,
    onLoading,
    fetchProps,
  } = props

  const location = useLocation()
  const channel = useContext(ChannelContext)
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true, parseArrays: false }) as SearchQueryType
  const targetPage = mode === PaginationModeEnum.CLASSIC ? Number.parseInt(searchParams.p!, 10) || 1 : 1
  const resultsPerPage = props.resultsPerPage || DEFAULT_RESULTS_PER_PAGE
  const threshold = props.threshold || DEFAULT_INFINITE_THRESHOLD
  const [page, setPage] = useState<number>(targetPage)
  const [pages, setPages] = useState<number>(0)
  const [items, setItems] = useState<ResultT[] | undefined[] | false>(props.initItems || false)
  const [itemsCount, setItemsCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const loadMoreRef = React.useRef<HTMLDivElement>()
  const [shouldObserve, setShouldObserve] = useState(false)
  const history = useContext(HistoryContext)

  const toggleLoading = (_loading: boolean) => {
    setIsLoading(_loading)
    if (onLoading) onLoading(_loading)
  }

  const handleObserver = (entities: any) => {
    const target = entities[0]

    if (target.isIntersecting && page < pages && !isLoading) {
      setPage(page + 1)
    }
  }

  const options = {
    root: null,
    threshold,
  }

  const getData = async (_page?: number, _resultPerPage?: number, fetchAllPages?: boolean) => {
    try {
      toggleLoading(true)
      const result = await fetchData(_page || page, _resultPerPage || resultsPerPage, fetchAllPages, fetchProps || {})

      if (fetchCallback) {
        fetchCallback(result.data)
      }
      const data = dataTransformer ? dataTransformer(result.data) : result.data

      if (mode === PaginationModeEnum.INFINITE && (!props.initItems || JSON.stringify(items) !== JSON.stringify(props.initItems))) {
        setItems(data.pager.pageCount == 0 && data.pager.currentPage == 1 ? items || [] : [...(items || []), ...data.items])
      } else {
        setItems(_.cloneDeep(data.items))
      }
      setPages(data.pager.pageCount)
      setItemsCount(data.pager.itemCount)
      //we don't set here isLoading to false, as it must be after items changed (rendered)
      //only if no results found we disable loader
      if (data.pager.pageCount == 0) {
        toggleLoading(false)
      }
    } catch (error: any) {
      console.error(error)
      toggleLoading(false)
      handleErrorResponse(error.response, undefined, props.skipDefaultError)
    }
  }

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true, parseArrays: false }) as SearchQueryType
    const urlPage = parseInt(params.p, 10) || 1
    if (urlPage !== page) {
      setPage(urlPage)
    }
  }, [location.search])

  const setLoadMoreRef = React.useCallback(
    (node: HTMLDivElement) => {
      loadMoreRef.current = node
      setShouldObserve(true)
    },
    [handleObserver],
  )

  useEffect(() => {
    const observer: IntersectionObserver | null = shouldObserve ? new IntersectionObserver(handleObserver, options) : null

    if (observer && loadMoreRef.current) {
      observer.observe(loadMoreRef.current)
    }

    return () => {
      if (observer && loadMoreRef.current) {
        observer.unobserve!(loadMoreRef.current)
      }
    }
  }, [page, pages, isLoading, shouldObserve, setLoadMoreRef])

  useEffect(() => {
    toggleLoading(false)
  }, [JSON.stringify(items)])

  useEffect(() => {
    if (Number.isInteger(page)) {
      const searchQuery = page > 1 ? { ..._.cloneDeep(searchParams), p: page } : _.cloneDeep(searchParams)
      const newPathname =
        history.location.pathname.startsWith(channel.current.path) && channel.current.path !== '/'
          ? history.location.pathname.replace(channel.current.path, '')
          : history.location.pathname

      const historyData = {
        pathname: newPathname,
        search: qs.stringify(searchQuery, { encode: false }),
        state: newPathname,
      }
      if (mode === PaginationModeEnum.CLASSIC) {
        history.replace(historyData)
      }

      const shouldFetch =
        mode === PaginationModeEnum.CLASSIC ||
        (mode === PaginationModeEnum.INFINITE && items && items.length !== 0 && JSON.stringify(items) !== JSON.stringify(props.initItems))
      if (shouldFetch) {
        getData()
      }
    }
  }, [page])

  useEffect(() => {
    if (mode === PaginationModeEnum.INFINITE) {
      getData(targetPage, resultsPerPage, true)
    }
    setPage(targetPage)
  }, [])

  const onChange = (event: React.ChangeEvent<any>, _page: number) => {
    setPage(_page)
  }

  const paginationItems = []

  for (let p = 1; p <= pages; p++) {
    paginationItems.push(<PaginationItem page={p} selected={p === page} />)
  }

  const spinnerClasses = {
    root: classNames(styles['pagination__spinner'], {
      [styles['pagination__spinner--infinite']]: mode !== PaginationModeEnum.CLASSIC,
    }),
  }

  return (
    <>
      <div className={styles['pagination']}>
        <div className={styles['pagination__data']}>
          <ResultsComponent
            //{`page-${page}-${itemsCount}`}
            page={page}
            pages={pages}
            items={items}
            itemsCount={itemsCount}
            {...resultsProps}
          >
            {props.children!}
          </ResultsComponent>
        </div>
        {isLoading && showLoader ? <LoadingSpinner classes={spinnerClasses} /> : null}
        {isLoading || mode === PaginationModeEnum.CLASSIC ? null : (
          <div className={styles['pagination--infinite__loadMore']} ref={setLoadMoreRef} />
        )}
      </div>
      {pages > 1 && mode === PaginationModeEnum.CLASSIC ? (
        <MaterialPagination
          count={pages}
          classes={{
            root: classNames(styles['pager'], {
              [styles['pager--left']]: pagerPosition === 'left',
              [styles['pager--right']]: pagerPosition === 'right',
            }),
          }}
          color="primary"
          page={page}
          onChange={onChange}
          disabled={isLoading}
          showFirstButton={showFirstButton!}
          showLastButton={showLastButton!}
        />
      ) : null}
    </>
  )
}

export default Pagination
